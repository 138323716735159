import React, { Component } from 'react';
import { Location } from "@reach/router";

const withLocation = WrappedComponent => {
  return class WithLocation extends Component {
    render() {
      return (
        <Location>
          {locationProps => <WrappedComponent {...locationProps} {...this.props} />}
        </Location>
      )
    }
  }
}

export default withLocation;
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CookieBar from 'components/CookieBar';

const App = ({ children }) => (
  <Fragment>
    {children}
    <CookieBar />
  </Fragment>
);

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;

import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage as L } from 'react-intl';
import _get from 'lodash/get';
import Link from 'components/Link';
import Cookies from 'js-cookie';
import withLocation from 'helpers/withLocation';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { toggleCookieBar as toggleCookieBarAction } from 'actions/index';
import { GDPR_GA, GDPR_TGM, GDPR_FB, isGdprOpen } from 'constants/cookieGdpr';
import WL from 'constants/whiteLabel';
import Rocket from 'utils/images/rocket-2.svg';
import Close from 'utils/images/close.svg';
import styles from './cookieBar.module.css';

class CookieBar extends Component {
  constructor(props) {
    super(props);

    this.refInput = React.createRef();
  }

  componentDidMount() {
    this.props.toggleCookieBar(isGdprOpen());
  }

  handleAccept(isOk = false) {
    const { location } = this.props;

    const checked = !!_get(this.refInput, 'current.checked', false);

    Cookies.set(GDPR_GA, checked, { expires: 3650 });
    Cookies.set(GDPR_TGM, checked, { expires: 3650 });
    Cookies.set(GDPR_FB, checked, { expires: 3650 });

    this.props.toggleCookieBar(false);

    checked && initializeAndTrack(location);
  }

  render() {
    const {
      isCookieBarOpen,
    } = this.props;

    if (!WL.showCookieBar || !isCookieBarOpen) {
      return null;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <img
            src={Close}
            alt="close"
            className={styles.close}
            onClick={() => this.props.toggleCookieBar(false)}
            role="presentation"
          />
          <div className={styles.root}>
            <div className={styles.imgBlock}>
              <img src={Rocket} alt="rocket" className={styles.icon}/>
            </div>
            <div>
              <div className={styles.description}>
                <L
                  id="cookieBar.text1"
                  defaultMessage="Please note! We use necessary and analytics cookies to improve the performance of our services and to understand how you interact with our website — learn more, please see our"
                />&nbsp;
                <Link
                  external
                  withLang
                  to="https://hiveos.farm/privacy"
                  className={styles.underlined}
                >
                  <L id="cookieBar.link" defaultMessage="Cookie Policy" />
                </Link>.&nbsp;
                <L id="cookieBar.text2" defaultMessage="To accept cookies, click OK." />
              </div>
              <div className={styles.row}>
                <div className={styles.checkboxBlock}>
                  <div>
                    <label htmlFor="necessary">
                      <input
                        type="checkbox"
                        id="necessary_cookie"
                        name="necessary"
                        checked
                        disabled={true}
                        className={styles.customCheckbox}
                      />
                      <span className={styles.labelCheckbox}>
                        <L id="cookieBar.necessary" defaultMessage="Necessary" />
                      </span>
                    </label>
                  </div>
                  <div>
                    <label htmlFor="statistics_cookie">
                      <input
                        type="checkbox"
                        id="statistics_cookie"
                        name="statistics"
                        ref={this.refInput}
                        defaultChecked
                        className={styles.customCheckbox}
                      />
                      <span className={styles.labelCheckbox}>
                        <L id="cookieBar.statistics" defaultMessage="Statistics" />
                      </span>
                    </label>
                  </div>
                </div>
                
                <button
                  className={styles.btn}
                  onClick={() => this.handleAccept()}
                >
                  <L id="cookieBar.ok" defaultMessage="OK" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CookieBar.propTypes = {
  toggleCookieBar: PropTypes.func.isRequired,
  isCookieBarOpen: PropTypes.bool,
};

const mapStateToProps = ({ root }) => ({
  isCookieBarOpen: root.isCookieBarOpen,
})

const mapDispatchToProps = {
  toggleCookieBar: toggleCookieBarAction,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocation(CookieBar));

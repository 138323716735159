const React = require("react")
const _get = require('lodash/get')
const App = require("./src/components/app.js").default
const { IntlProvider } = require("react-intl")
const { WL } = require("./src/constants/whiteLabel")
const { ReduxWrapper } = require("./src/ReduxWrapper")

const LANGS_SUFIX = ['ru', 'zh' ];

const PAGES_WITH_PREVENT_SCROLL = [
  '/asic',
  '/asic/l3',
  '/asic/s9',
  '/asic/s10',
  '/asic/s17',
  '/asic/t9',
  '/asic/t17',
  '/changelog',
];

const getPreventScrollPages = (pathes = [], langs = []) => {
  return pathes.reduce((acc, path) => {
    const pagesWithLangSufix = [`${path}/`, ...langs.map(lang => `${path}_${lang}/`)];
    return [...acc, ...pagesWithLangSufix];
  }, []);
}

exports.wrapPageElement = ({ element }) => {
  const contextLang = _get(element, 'props.pageContext.lang', '');
  const contextLangKb = _get(element, 'props.pageContext.currentLang', '');
  const lang = contextLang || contextLangKb || 'en';
  const messages = WL.getMessages();
  const currentMessages = _get(messages, lang, messages.en);

  return (
    <ReduxWrapper>
      <IntlProvider locale={lang} messages={currentMessages}>
        <App>
          {element}
        </App>
      </IntlProvider>
    </ReduxWrapper>
  );
};

exports.shouldUpdateScroll = ({
  routerProps,
  prevRouterProps,
}) => {
  const location = _get(routerProps, 'location');
  const prevLocation = _get(prevRouterProps, 'location');

  const preventScrollPathes = getPreventScrollPages(PAGES_WITH_PREVENT_SCROLL, LANGS_SUFIX);

  // check prevent scroll pathes to prevent scroll between them
  if (
    preventScrollPathes.includes(_get(location, 'pathname')) &&
    preventScrollPathes.includes(_get(prevLocation, 'pathname'))
  ) {
    return false;
  }

  return true;
}

exports.onClientEntry = () => {
  require('lazysizes');
  require('css-vars-ponyfill').default();
  require('es6-symbol/implement');
};

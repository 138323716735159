import { combineReducers } from 'redux';
import { TOGGLE_COOKIEBAR } from 'actions/index';

const initialState = {
  isCookieBarOpen: null,
}

const root = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_COOKIEBAR:
      return {
        ...state,
        isCookieBarOpen: payload,
      }
    default:
      return state
  }
}

export default combineReducers({ root })

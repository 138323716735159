import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { useIntl } from 'react-intl';
import languages from 'constants/languages';
import { preparePathWithSlash } from 'helpers/index';
import _omit from 'lodash/omit';
import WL from 'constants/whiteLabel';

const preparePathWithQuery = (lang, path) => {
  const isSlashBeforeHash = path.split('?')[0].slice(-1) === "/";
  if (isSlashBeforeHash) {
    const preparedPathStart = path.split('?')[0].slice(0, -1);
    const preparedPathEnd = path.split('?')[1];
    return `${preparedPathStart}_${lang}/?${preparedPathEnd}`
  } else {
    const preparedPathStart = path.split('?')[0];
    const preparedPathEnd = path.split('?')[1];
    return `${preparedPathStart}_${lang}/?${preparedPathEnd}`
  }
};

const preparePathWithHash = (lang, path) => {
  const isSlashBeforeHash = path.split('#')[0].slice(-1) === "/";
  if (isSlashBeforeHash) {
    const preparedPathStart = path.split('#')[0].slice(0, -1);
    const preparedPathEnd = path.split('#')[1];
    return `${preparedPathStart}_${lang}/#${preparedPathEnd}`
  } else {
    const preparedPathStart = path.split('#')[0];
    const preparedPathEnd = path.split('#')[1];
    return `${preparedPathStart}_${lang}/#${preparedPathEnd}`
  }
};

const prepareLinkLangPath = (lang, pathname) => {
  if (lang === languages.EN || !pathname) {
    return pathname;
  }

  if (pathname.includes('?')) {
    return preparePathWithQuery(lang, pathname)
  }

  if (pathname.includes('#')) {
    return preparePathWithHash(lang, pathname)
  }

  if (pathname === '/') {
    return `${pathname}${lang}`
  }

  return `${pathname}_${lang}`
};

const Link = ({ to, href, withLang, children, external, ...props }) => {
  const intl = useIntl();

  const preparedLinkPath = withLang ?
  preparePathWithSlash(prepareLinkLangPath(intl.locale, to)) :
  preparePathWithSlash(to);

  let externalHref = href || to;

  if (external && WL.liksWithLocale.includes(href)) {
    externalHref = `${href}?lang=${intl.locale}`;
  }

  if (external) {
    return <a target="_blank" rel="noreferrer" {..._omit(props, ['noRef'])} href={externalHref}>{children}</a>
  }

  return <GatsbyLink to={preparedLinkPath} {...props}>{children}</GatsbyLink>
};

export default Link;

